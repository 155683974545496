import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// delete cache from old nagiostv serviceworker cache
// TODO: delete this later

// try {
//   caches.keys().then(function(names) {
//     for (let name of names)
//       caches.delete(name);
//   });
// } catch (e) {
//   console.log('Had a problem clearing the serviceWorker cache.');
// }
