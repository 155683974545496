import React, { useState, useEffect } from 'react';
//import logo from './logo.svg';
import './Base.css';
import {
  Link
} from "react-router-dom";

function Base() {

  const [demoEnabled, setDemoEnabled] = useState(true);

  useEffect(() => {
    console.log('useEffect()');
    
    // component mount
    startTimer();

    return () => {
      // component unmount
      stopTimer();
    };
  },
    // https://github.com/facebook/create-react-app/issues/6880
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let timerHandle = null;

  const startTimer = () => {
    if (timerHandle === null) {
      timerHandle = setTimeout(() => {
        demoOff();
        timerHandle = null;
      }, 5 * 60 * 1000);
    }
  };

  const stopTimer = () => {
    if (timerHandle) {
      clearTimeout(timerHandle);
      timerHandle = null;
    }
  };

  const demoOn = () => {
    setDemoEnabled(true);
    startTimer();
  };

  const demoOff = () => {
    setDemoEnabled(false);
    stopTimer();
  };

  

  

  return (
    <div className="Base">
      
      <div className="step1">
    	  <div className="title">NagiosTV</div>
  	  </div>
  	
      <div className="step2">


        <div className="step2-right">
          
          
          
          <div style={{ marginTop: '12px' }}>

            <div style={{paddingRight: '10px' }}>
              <img alt="App Icon" style={{ width: '100px', border: '2px solid #335b77' }} src="apple-touch-icon.png" />
            </div>

            <div style={{marginTop: 15, paddingRight: '10px' }}>
              <img alt="NagiosTV v0.9.0 screenshot" style={{ width: '100%', border: '2px solid #335b77' }} src="images/nagiostv-0.9.0-short.png" />
            </div>

            <div style={{ marginTop: '10px', fontStyle: 'italic' }}>
              NagiosTV is a user interface (UI) add-on for the Nagios monitoring system. Compatible with Nagios Core 4, Nagios XI, and MK Livestatus.<br />
              <br />
              Bubble up the most important stuff to the top. Focus only on the work that needs to be done.<br />
              <br />
              Large bold fonts and bright colors - so it's easily read from across the room.<br />
              <br />
              A great conversation piece during an outage.<br />
              <br />
              Double your fun with multiple Nagios servers watching your infrastructure from multiple locations.<br />
              <br />
              Looks great on wall mounted TV, desktop, and mobile.<br />
              <br />
              This is not meant to be a replacement for the entire Nagios web interface, but as a supplement to the "what's down?" part.
              <br />
              Easy install - Download, extract, move into place. Done.<br />
            </div>
          </div>

          
          
          <div style={{ marginTop: '40px', paddingBottom: '15px' }}>
            
            <a href="https://github.com/chriscareycode/nagiostv-react" target="_blank" rel="noopener noreferrer"><button>Download NagiosTV at GitHub</button></a>
            
            <span style={{ display: 'inline-block', position: 'relative', top: 6, marginLeft: 15 }}>
              <iframe src="https://ghbtns.com/github-btn.html?user=chriscareycode&repo=nagiostv-react&type=star&count=true&size=large" frameborder="0" scrolling="0" width="170" height="30" title="GitHub"></iframe>
            </span>
            
            <br />
            <br />
            <a href="https://nagiostv.com/demo/" target="_blank" rel="noopener noreferrer"><button>Launch the demo full screen</button></a>
          </div>

          <div style={{ marginTop: '20px' }}>
            
          </div>
          
          

        </div>
        
      </div>


      {/* <div className="step3">
        <div style={{ textAlign: 'center' }}>
          I built NagiosTV to monitor my own infrastructure and share it with you to enjoy free of charge.
        </div>
  	  </div> */}

      {/*<div className="step4">
        <div style={{ textAlign: 'center' }}>
          <img alt="NagiosTV v0.6.2 screenshot" style={{ width: '100%', border: '4px solid #444' }} src="nagiostv-0.6.2.png" />
        </div>
  	  </div>*/}

      <div className="step3b">
        <div>
          Some of the features (read more on GitHub):

          <ul>
            <li>International language support - English, Spanish, and French so far</li>
            <li>International Date support</li>
            <li>Alerts slide in and out of place with smooth animations.</li>
            <li>Sorting - Ability to order items newest first or oldest first</li>
            <li>Filter by HostGroup</li>
            <li>Filter by State - hide WARNING, CRITICAL, ACKED, SCHEDULED, UNKNOWN or FLAPPING states</li>
            <li>Show or hide the Hosts, Services, or Alert History sections</li>
            <li>Customizable sound effects for state changes</li>
            <li>Speak the state changes with Text-To-Speech</li>
            <li>Display your own logo or custom image</li>
            <li>Doom Guy (Flynn), the character from the game "Doom". This is just a bit of added fun to bring some emotion to server monitoring. The more services are down, the more angry Flynn gets.</li>
            
          </ul>
        </div>
  	  </div>

      

      <div className="step4">
        <div>
          <h2>Frequently Asked Questions (FAQ)</h2>
          <Link to="/bypassing-authentication" style={{ color: '#162835' }}>Bypassing authentication on NagiosTV</Link>
        </div>
        
  	  </div>

      <div className="step5">
        

    	  NagiosTV by <a href="https://chriscarey.com" target="_blank" rel="noreferrer noopener">Chris Carey</a>
  	  </div>
      
      <div className="iframe-wrap">

        {demoEnabled && <iframe title="NagiosTV Demo" frameBorder="0" src="https://nagiostv.com/demo/"></iframe>}

        {!demoEnabled && <div className="iframe-placeholder">
          <div>
            Demo is disabled after 5 minutes to save CPU.<br />
            <button disabled={demoEnabled} onClick={demoOn}>Click here</button> to turn it back on.
          </div>
        </div>}

        {/*
        <div className="iframe-bottom">
          Demo Mode <button disabled={demoEnabled} onClick={demoOn}>On</button> / <button disabled={!demoEnabled} onClick={demoOff}>Off</button>
          &nbsp;
          &nbsp;
          &nbsp;
          <a href="https://nagiostv.com/demo/" target="_blank" rel="noopener noreferrer"><button>Launch the demo full screen</button></a>
        </div>
        */}

      </div>


    </div>
  );
}

export default Base;
