//import logo from './logo.svg';
import './BypassAuthentication.css';
import {
  Link
} from "react-router-dom";

function BypassAuthentication() {
  return (
    
    <div className="BypassAuthentication">
    
      <div>
        <Link to="/">&lt;- Go back to NagiosTV home page</Link>
      </div>

      <h2>
        Bypassing authentication in NagiosTV
      </h2>

      <div>
        The default instructions for installing NagiosTV is to have you place the nagiostv/ folder inside the Nagios web UI folder.<br />
        This causes NagiosTV to share the same authentication as the Nagios web UI when you visit http://example.com/nagios/nagiostv/ if your Nagios is found at http://example.com/nagios/. 
        This is the best method since it shares the username/password auth used for Nagios web UI.
        <br />
        <br />
        There are some circumstances where you may want to bypass authentication. For example, if you are running the display on a television that would be difficult to deal with the login prompt.
        It is worth noting here that removing authentication for NagiosTV can decrease the security of your Nagios installation. If you do this, make sure your server has some sort of alternate authentication, VPN, private LAN, and not directly accessible unauthenticated from the Internet.<br />

        <br />
        Also I take no responsibility for issues brought on by doing these techniques since I cannot control if your network is not secure or if you have exposed your server publicly in a way it shouldn't be.
      </div>



      <div>
        <h3>Summary</h3>
        First step is to move the nagiostv/ folder to a location on your web server that does not require authentication.<br />
        This will allow the NagiosTV web ui to be loaded without a username/password prompt.<br />
        You may have already done this and noticed NagiosTV is popping up a login prompt every time it polls the server which is why you were looking up how to bypass auth.<br />
        <br />

        Secondly we need to use one of the following techniques:<br />
        <br />
        <div className="left-pad">
          Option 1) Use MK Livestatus<br />
          Option 2) Use Apache to proxy and add auth<br />
          Option 3) Run a Node.js script to create a proxy that adds auth<br />
          Option 4) There is a hacky technique to hard code one default user into your Nagios cgi-bin scripts, and you can remove auth requirement to two required cgis with a few apache configs. <br />
        </div>
      </div>

      <br />

      <div>With the proxy techniques, if the proxy connects to the Nagios server via http instead of https, the username and password will be sent in clear text from the proxy server (Node.js or Apache) to the Nagios server. For this reason it is preferable if the proxy server is running on the same machine so it will not actually go across the wire in the clear. If your proxy server is running on a different machine than the Nagios server, then use of TLS (https) is a very good idea.</div>
      <br />
      <div>Authenticating to your Nagios web server or cgi-bin folder over http in general will send your username and password in the clear. So this is not a specific issue for the proxy server only, but a general warning about using http. Not ok for the Internet or untrusted network. Probably ok for the casual user running Nagios on a private network. For higher security environments, always connect to Nagios over https to protect against snooping even within your private network.</div>
      <br />

      <div>
        <h3>Move NagiosTV to a web location without authentication</h3>
        You could move nagiostv/ folder inside your web root (replace /var/www/html with your web root)
        <div class="left-pad">

          <div class="code"># mv nagiostv /var/www/html</div>
          This would make NagiosTV available at http://example.com/nagiostv/
        </div>
        
        <br />

        Or you could move nagiostv/ to some other location on the hard drive and configure an Apache "Alias" to point to it.<br />

        <div className="top-pad left-pad">
          Move nagiostv/ folder to some other location such as /usr/local/nagios/nagiostv:
          <div class="code"># mv nagiostv /usr/local/nagios/</div>
          Add the Alias configuration option to your Apache config apache.cfg, or in another one of your config files:
          <div class="code">Alias /nagiostv "/usr/local/nagios/nagiostv"</div>
          Reload Apache<br />
          <br />
          This would make NagiosTV available at http://example.com/nagiostv/
        </div>

      </div>

      <br />

      <div>
        <h3>Option 1) Connect using MK Livestatus</h3>

        <div className="left-pad">
          <div className="the-good">
            GOOD: This option does not require hard coding a username or password anywhere.
          </div>
          <div className="the-bad" style={{ color: 'orange' }}>
            MEDIUM: This option does open up a URL to proxy requests through to MK Livestatus command file.<br />Though we mitigate the impact/risk by only allowing specific read-only actions that NagiosTV requires.
          </div>
          
          <br />
          I'm going to begin my own writeup on this technique when time allows.<br />
          In the meantime, <a href="https://cyb3rdelic.github.io/linux/2021/01/18/NagiosTV-With-Livesocket.html" target="_blank" rel="noopener noreferrer">this writeup on Cyb3rdelic's GitHub site</a> is very good at helping you download, compile and install MK Livestatus, and set it up with NagiosTV.
          Thank you to cyb3rdelic for putting this writeup together.
        </div>
      </div>

      <br />

      <div>
        <h3>Option 2) Connect through a proxy using Apache</h3>

        <div className="left-pad">
          <div className="the-good">
            GOOD: Fairly easy setup
          </div>
          <div className="the-good">GOOD: Always running if Apache is running</div>
          <div className="the-bad">BAD: This option will require your username and password to be saved in base64 encoding in the Apache configuration file on the server</div>
          <br />
          Configure Apache with mod_proxy and mod_headers add authentication headers and proxy the request through to the Nagios cgi-bin folder<br />
          <br />
          We need to enable a few modules on our Apache if they are not already enabled. Then restart Apache.
          <div className="code">
            a2enmod proxy<br />
            a2enmod proxy_http<br />
            a2enmod headers<br />
            systemctl restart apache2
          </div>
          <br />
          Then create the file <strong>/etc/apache2/sites-enabled/nagiostv.conf</strong> which will serve two purposes:<br />
          1) Serve the NagiosTV web interface from the /usr/local/nagios/nagiostv folder<br />
          2) Create a proxy at /nagios-proxy/ that will add authentication and forward requests to the Nagios cgi-bin URL<br />
          <pre className="code">{`# Serve the NagiosTV website from a custom location
Alias /nagiostv "/usr/local/nagios/nagiostv"

# Configure access control on that location to allow access
<Directory "/usr/local/nagios/nagiostv">
  <IfVersion >= 2.3>
     <RequireAll>
       Require all granted
     </RequireAll>
  </IfVersion>
  <IfVersion < 2.3>
     Order allow,deny
     Allow from all
  </IfVersion>
</Directory>

# Add a Proxy which automatically adds Authentication at the URL /nagios-proxy
<Location "/nagios-proxy">
  ProxyPass http://127.0.0.1/nagios/cgi-bin/
  ProxyPassReverse http://127.0.0.1/nagios/cgi-bin/
  RequestHeader set Authorization "Basic xxxxxxxxxxxxxxxxxxxxxxxx"
</Location>
`}

          </pre>
          In the file above, you will need to change two things<br />
          <br />
          1) Change 127.0.0.1 to the IP address or domain name of your Nagios server. Unless it's on the same machine then you can leave this alone.<br />
          2) Change the Basic authorization password - where the xxxxxxxxxxxxxxxxxxxxxxxx is.<br />
          <br />
          To get the Basic authorization value for the above, use this command. Enter the code into the above command after "Basic"
          <div className="code">echo -n "my-username:my-password" | base64 </div>
          <br />
          Restart Apache again and make sure it loads ok
          <div className="code">
            systemctl restart apache2
          </div>

          <br />
          Now you can test if the proxy is automatically adding authentication correctly by hitting a URL like this in a web browser:<br />
          <div className="">http://your-server-name/nagios-proxy/statusjson.cgi</div>
          <div>It should output some JSON to the screen instead of popping up an authentication, if things are set up correctly.</div>
          <br />
          Configure NagiosTV settings to point to the Apache proxy:
          <ul>
              <li>Launch NagiosTV in your web browser. If you configured like above this would be at http://your-server-name/nagiostv/</li>
              <li>Load the settings screen within NagiosTV UI</li>
              <li>Enter the Apache proxy URL in the cgi-bin field. In my case it was `/nagios-proxy/` as configured above. If your NagiosTV is on a different machine than your Nagios Apache Proxy, you can enter a full URL like http://your-server-name/nagios-proxy/</li>
              <li>Save Settings</li>
            </ul>
        </div>
      </div>

    <br />

      <div>
        <h3>Option 3) Connect through a proxy using Node.js</h3>

        <div className="left-pad">

          <div className="the-good">GOOD: Fairly easy setup</div>
          
          <div className="the-bad">BAD: You have to launch this every time the server starts. Currently a startup script is not included</div>
          <div className="the-bad">BAD: This option will require your username and password to be saved in clear text in a configuration file on the server</div>

          Install Node.js on your server if it is not already installed:<br />
          <a target="_blank" rel="noopener noreferrer" href="https://nodejs.org/en/download/package-manager/">Installing Node.js via package manager</a><br />
          <br />

          TODO: This HOWTO is not complete yet
          {/*
          Change directory into your nagiostv/ folder (swap /var/www/html/nagiostv for your own folder path):
          <div class="code"># cd /var/www/html/nagiostv</div>

          Configure the included Node.js proxy:
          <div class="code"># nano nodejs-proxy-config.ini</div>

          Run the included Node.js proxy (this would be best to run as a non-root user since it does not need root permissions):
          <div class="code">$ node app.js</div>
          */}
          <div className="top-pad">
            After launching the Node.js proxy, it should tell you the configuration option to enter in NagiosTV settings.<br />
            <br />
            Configure NagiosTV settings to point to the Apache proxy:
            <ul>
              <li>Launch NagiosTV in your web browser</li>
              <li>Load the settings screen</li>
              <li>Enter the proxy URL in the cgi-bin field.</li>
              <li>Save Settings</li>
            </ul>
          </div>
          
        </div>
      </div>



      

      

      <br />

      <div>
        <h3>Option 4) Hard code a default user into your cgi-bin scripts, and remove auth to a couple of the cgis</h3>
        
        <div className="left-pad">
        
          <div className="the-bad">BAD: This option requires removing auth from two files in the cgi-bin folder.<br />
          It also hard-codes a username in your Nagios cgi-bin configuration file cgi.cfg. This will cause every action on the entire server to act as this one user - not only NagiosTV but the Nagios web UI as well. You can only use this technique if you manage your Nagios as one user. Multi user setups will not work.</div>
          <br />
          TODO: This HOWTO is not complete yet
        
        </div>

      </div>
    
      <br />

      <div>
        <Link to="/">&lt;- Go back to NagiosTV home page</Link>
      </div>

    </div>
    
  );
}

export default BypassAuthentication;
