//import logo from './logo.svg';
import './App.css';
import Base from './Base.js';
import BypassAuthentication from './BypassAuthentication.js';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          
          <Route path="/bypassing-authentication">
            <BypassAuthentication />
          </Route>

          <Route path="/">
            <Base />
          </Route>

        </Switch>
      
        
        
      </div>
    </Router>
  );
}

export default App;
